html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  width: 100%;
  height: 100%;
  background: black;

  .bsod {
    background: navy;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    color: white !important;
    font-size: 1.55rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .bsod__content {
      max-width: 800px;
      margin: auto;

      padding: 12px;
      p {
        color: white !important;
      }
    }
  }
}

.modal {
  /* Target only the immediate second child */
  & > *:nth-child(2) {
    display: inline-block;
    height: 100%;
    /* overflow-y: scroll; */
    -webkit-overflow-scrolling: touch;
  }
}
